import { listByCourseSeriesIdAdmin } from '@/api/entity/course'
import { getByCourseSeriesId } from '@/states/couseSeries'
import { Course } from 'momai'
import { computed, ref } from 'vue'

export default ({
  courseSeriesId
}: {
  courseSeriesId: number;
}) => {
  const courseList = ref<Course[]>([])
  const couseSeriesDetail = computed(() => getByCourseSeriesId.value(Number(courseSeriesId)))
  const fetchCourseList = () => {
    listByCourseSeriesIdAdmin(Number(courseSeriesId)).then(list => {
      courseList.value = list
    })
  }

  fetchCourseList()

  return {
    couseSeriesDetail,
    fetchCourseList,
    courseList
  }
}
