
import { useRoute } from 'vue-router'
import { detail, del, update } from '@/api/entity/course-series'
import { CourseSeries, createCourseSeries } from 'momai'
import { computed, ref } from 'vue'
import { courseSeriesTags } from '@/states/tags'
import { Dialog, Notify, Toast } from 'vant'
import { fetchCourseSeries, courseSeriesList } from '@/states/couseSeries'
import { goBack } from '@/states/layout'
import useCourseList from './useCourseList'
import { activeTab } from './state'
import CourseSeriesForm from '../Form.vue'

export default {
  components: {
    CourseSeriesForm
  },
  setup: () => {
    const { courseSeriesId } = useRoute().params
    const courseSeriesInfo = ref<CourseSeries>(createCourseSeries())
    const formatCash = () => {
      courseSeriesInfo.value.cashPrice = (Number(courseSeriesInfo.value.cashPrice) / 100).toFixed(2)
      courseSeriesInfo.value.salePrice = (Number(courseSeriesInfo.value?.salePrice) / 100).toFixed(2)
    }
    detail(Number(courseSeriesId)).then(r => {
      courseSeriesInfo.value = r
      formatCash()
    })
    const tIds = computed(() => courseSeriesInfo.value.tag.split(','))
    const selectedTags = computed(() => courseSeriesTags.value.filter(t => tIds.value.some(tId => Number(tId) === t.id)))
    return {
      ...useCourseList({
        courseSeriesId: Number(courseSeriesId)
      }),
      activeTab,
      courseSeriesInfo,
      selectedTags,
      askDel: () => {
        Dialog.confirm({
          title: '确认',
          message: '确认删除吗课程系列吗？删除后不可恢复！'
        })
          .then(() => {
            del(Number(courseSeriesId)).then(() => {
              fetchCourseSeries()
              goBack()
              Notify({
                type: 'success',
                message: '删除成功！'
              })
            })
          })
          .catch(() => {
          })
      },
      doSave: () => {
        update(courseSeriesInfo.value).then(() => {
          courseSeriesList.value.splice(courseSeriesList.value.findIndex(c => c.id === Number(courseSeriesId)), 1, courseSeriesInfo.value)
          Toast.success('课程系列信息保存成功')
          formatCash()
        })
      }
    }
  }
}
